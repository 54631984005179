import React, { useState } from 'react'
import { StyleSheet, FlatList, TouchableOpacity } from 'react-native'
import jsonSetting from '../settingList.json'
import { Octicons } from '@expo/vector-icons'
import { Text, View } from '../components/Themed'

const goTo = (navigation: any, name: string) => {
  navigation.navigate('Details', {
    name: name,
  })
}

export default function ProfileScreen({ navigation }: any) {
  const [settingJson] = useState(jsonSetting)
  return (
    <View style={styles.container}>
      <FlatList
        renderItem={({ item, index }) => {
          return (
            <TouchableOpacity
              onPress={() => {
                goTo(navigation, item.name)
              }}
            >
              <Text style={styles.listStyle} key={index}>
                {item.name}
                <Octicons
                  name="chevron-right"
                  size={24}
                  color="black"
                  style={styles.iconStyle}
                />
              </Text>
            </TouchableOpacity>
          )
        }}
        data={settingJson}
        keyExtractor={(item, index) => index.toString()}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    width: '100%',
  },
  title: {
    fontSize: 20,
    color: 'white',
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  listStyle: {
    fontSize: 20,
    fontWeight: '600',
    height: 50,
    marginLeft: 20,
  },
  iconStyle: {
    position: 'relative',
    top: 3,
    left: 20,
    fontSize: 23,
  },
})
