import React, { useEffect, useState } from 'react'
import { TouchableOpacity, StyleSheet, Dimensions, Image } from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import { Text, View } from '../components/Themed'
const { width } = Dimensions.get('window')

const longText = (text: string) => {
  if (text && text.length > 13) {
    return text.substring(0, 15) + '...'
  }
  if (text) {
    return text
  }
  return ''
}

const goTo = (navigation: any, item: string) => {
  console.log(item.id)
  navigation.navigate('ItemDetails', {
    id: item.id,
  })
}

const favoriteIcon = (isFav: boolean) => {
  return (
    <AntDesign
      style={styles.itemFavorite}
      name={isFav ? 'heart' : 'hearto'}
      size={20}
    />
  )
}

const Item = ({ itemData, navigation }: any) => {
  const [isFavorite, setToFavorite] = useState(itemData.isFavorite)

  return (
    <View style={styles.itemWrapper}>
      <TouchableOpacity onPress={() => goTo(navigation, itemData)}>
        <View style={styles.itemContainer}>
          <Image
            source={{
              uri:
                `https://ecommerce.inovacion.al/` + itemData.full_image_default,
            }}
            accessible
            style={styles.itemImage}
          />
        </View>
        <View style={styles.titleWrapper}>
          <Text style={styles.itemName}>{longText(itemData.name)}</Text>
          <TouchableOpacity onPress={() => setToFavorite(true)}>
            {favoriteIcon(isFavorite)}
          </TouchableOpacity>
        </View>
        <Text style={styles.itemPrice}>${itemData.sale_price}</Text>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  itemWrapper: {
    paddingBottom: 15,
    flex: 1,
    padding: 5,
    width: width <= 400 ? 170 : 200,
  },
  itemContainer: {
    flexDirection: 'row',
    borderRadius: 2,
    marginBottom: 0,
    margin: width >= 640 ? 10 : 0,
    height: 200,
  },
  itemImage: {
    height: '100%',
    width: '100%',
    borderRadius: 10,
    resizeMode: 'contain',
  },
  itemName: {
    fontWeight: '600',
    flex: 3,
  },
  itemFavorite: {
    fontWeight: 'bold',
    color: '#dc3545',
    marginEnd: width >= 640 ? 10 : 0,
  },
  itemPrice: {
    color: 'rgba(0, 0, 0, 0.3);',
    fontWeight: '700',
    position: 'relative',
    top: -5,
  },
  titleWrapper: {
    paddingTop: 5,
    flexDirection: 'row',
  },
})
export default Item
