import { Ionicons } from '@expo/vector-icons'
import { SimpleLineIcons } from '@expo/vector-icons'
import { AntDesign } from '@expo/vector-icons'
import { FontAwesome } from '@expo/vector-icons'
import { FontAwesome5 } from '@expo/vector-icons'
import { Feather } from '@expo/vector-icons'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { createStackNavigator } from '@react-navigation/stack'
import * as React from 'react'
import { Text, TouchableOpacity, StyleSheet, View } from 'react-native'

import Colors from '../constants/Colors'
import useColorScheme from '../hooks/useColorScheme'
import Home from '../screens/Home'
import Search from '../screens/Search'
import Cart from '../screens/Cart'
import Favorites from '../screens/Favorites'
import User from '../screens/User'
import Details from '../screens/UserScreenDetails'
import {
  BottomTabParamList,
  HomeParamList,
  SearchParamList,
  CartParamList,
  FavoritesParamList,
  UserParamList,
  DetailsTypes,
} from '../types'
import FavoriteScreen from '../screens/Favorites'
import Navigation from './index'

const BottomTab = createBottomTabNavigator<BottomTabParamList>()

export default function BottomTabNavigator() {
  const colorScheme = useColorScheme()

  return (
    <BottomTab.Navigator
      lazy={true}
      screenOptions={({ route }) => ({
        tabBarIcon: ({ focused, color, size }) => {
          let iconName: string | any = ''

          if (route.name === 'Home') {
            iconName = focused ? 'ios-home-sharp' : 'ios-home-outline'
            return <TabBarIcon name={iconName} color={color} size={30} />
          } else if (route.name === 'Search') {
            iconName = focused ? 'ios-search' : 'search-outline'
            return <TabBarIcon name={iconName} color={color} size={34} />
          } else if (route.name === 'Cart') {
            if (focused) {
              return <FontAwesomeIcon name="shopping-bag" color={color} />
            }
            return <SimpleLinesIcon name="handbag" color={color} />
          } else if (route.name === 'Favorites') {
            iconName = focused ? 'heart' : 'hearto'
            return <AntDesignIcon name={iconName} color={color} />
          } else if (route.name === 'User') {
            iconName = focused ? 'user-alt' : 'user'
            return <FontAwesome5 name={iconName} color={color} size={30} />
          }
          return <TabBarIcon name={iconName} color={color} size={size} />
        },
      })}
      tabBarOptions={{
        showLabel: false,
        activeTintColor: Colors[colorScheme].text,
        inactiveTintColor: Colors.light.text,
      }}
    >
      <BottomTab.Screen name="Home" component={HomeNavigator} />
      <BottomTab.Screen name="Search" component={SearchNavigator} />
      <BottomTab.Screen name="Cart" component={CartNavigator} />
      <BottomTab.Screen name="Favorites" component={FavoritesNavigator} />
      <BottomTab.Screen name="User" component={UserNavigator} />
    </BottomTab.Navigator>
  )
}

// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
function TabBarIcon(props: {
  name: React.ComponentProps<typeof Ionicons>['name']
  color: string
  size: number
}) {
  return <Ionicons style={{ marginBottom: -3 }} {...props} />
}

const SimpleLinesIcon = (props: {
  name: React.ComponentProps<typeof SimpleLineIcons>['name']
  color: string
}) => {
  return <SimpleLineIcons size={30} style={{ marginBottom: -3 }} {...props} />
}
const AntDesignIcon = (props: {
  name: React.ComponentProps<typeof AntDesign>['name']
  color: string
}) => {
  return <AntDesign size={30} style={{ marginBottom: -3 }} {...props} />
}
const FontAwesomeIcon = (props: {
  name: React.ComponentProps<typeof FontAwesome>['name']
  color: string
}) => {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />
}

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
const HomeStack = createStackNavigator<HomeParamList>()

function HomeNavigator() {
  return (
    <HomeStack.Navigator>
      <HomeStack.Screen
        name="HomeScreen"
        component={Home}
        options={({ navigation, route }) => ({
          headerShown: true,
          headerStyle: {
            backgroundColor: '#fff',
            height: 45,
            borderBottomWidth: 0.2,
          },
          title: '',
          headerTitle: 'Delirium',
          headerTintColor: '#000',
          headerRight: (props) => (
            <Feather
              {...props}
              name="send"
              style={styles.rightIconHeader}
              size={25}
              onPress={() => {
                alert('Clicked !')
              }}
            />
          ),
          headerTitleStyle: {
            fontWeight: '500',
            display: 'flex',
            fontSize: 30,
            position: 'relative',
            paddingLeft: 3,
            justifyContent: 'flex-start',
            fontFamily: 'fontSpring',
            borderBottomWidth: 0,
          },
        })}
      />
    </HomeStack.Navigator>
  )
}

const SearchStack = createStackNavigator<SearchParamList>()

function SearchNavigator() {
  return (
    <SearchStack.Navigator>
      <SearchStack.Screen
        name="SearchScreen"
        component={Search}
        options={() => ({
          headerShown: true,
          headerStyle: {
            backgroundColor: '#fff',
            height: 40,
            borderBottomWidth: 0,
          },
          headerTitle: 'Delirium',
          headerTintColor: '#000',
          headerTitleStyle: {
            fontWeight: '500',
            display: 'flex',
            fontSize: 30,
            justifyContent: 'flex-start',
            position: 'relative',
            paddingLeft: 3,
            fontFamily: 'fontSpring',
            borderBottomWidth: 0,
          },
        })}
      />
    </SearchStack.Navigator>
  )
}

const CartStack = createStackNavigator<CartParamList>()

function CartNavigator({ navigation }: any) {
  return (
    <CartStack.Navigator mode="modal">
      <CartStack.Screen
        name="CartScreen"
        component={Cart}
        options={({ navigation, route }) => ({
          headerShown: true,

          headerStyle: {
            backgroundColor: '#fff',
            height: 45,
            borderBottomWidth: 0.2,
          },
          title: '',
          headerTitle: 'Delirium',
          headerTintColor: '#000',
          headerRight: (props) => (
            <Feather
              {...props}
              name="send"
              style={styles.rightIconHeader}
              size={25}
              onPress={() => {
                alert('Clicked !')
              }}
            />
          ),
          headerTitleStyle: {
            fontWeight: '500',
            display: 'flex',
            fontSize: 30,
            position: 'relative',
            paddingLeft: 3,
            justifyContent: 'flex-start',
            fontFamily: 'fontSpring',
            borderBottomWidth: 0,
          },
        })}
      />
    </CartStack.Navigator>
  )
}

const FavoritesStack = createStackNavigator<FavoritesParamList>()

function FavoritesNavigator() {
  return (
    <FavoritesStack.Navigator>
      <FavoritesStack.Screen
        name="FavoritesScreen"
        component={Favorites}
        options={({ navigation, route }) => ({
          headerShown: true,
          headerStyle: {
            backgroundColor: '#fff',
            height: 45,
            borderBottomWidth: 0.2,
          },
          title: '',
          headerTitle: 'Delirium',
          headerTintColor: '#000',
          headerRight: (props) => (
            <Feather
              {...props}
              name="send"
              style={styles.rightIconHeader}
              size={25}
              onPress={() => {
                alert('Clicked !')
              }}
            />
          ),
          headerTitleStyle: {
            fontWeight: '500',
            display: 'flex',
            fontSize: 30,
            position: 'relative',
            paddingLeft: 3,
            justifyContent: 'flex-start',
            fontFamily: 'fontSpring',
            borderBottomWidth: 0,
          },
        })}
      />
    </FavoritesStack.Navigator>
  )
}

const UserStack = createStackNavigator<UserParamList>()
const DetailsStack = createStackNavigator<DetailsTypes>()

function UserNavigator() {
  return (
    <UserStack.Navigator>
      <UserStack.Screen
        name="UserScreen"
        component={User}
        options={({ navigation, route }) => ({
          headerShown: true,
          headerStyle: {
            backgroundColor: '#fff',
            height: 45,
            borderBottomWidth: 0.2,
          },
          title: '',
          headerTitle: 'Delirium',
          headerTintColor: '#000',
          headerRight: (props) => (
            <Feather
              {...props}
              name="send"
              style={styles.rightIconHeader}
              size={25}
              onPress={() => {
                alert('Clicked !')
              }}
            />
          ),
          headerTitleStyle: {
            fontWeight: '500',
            display: 'flex',
            fontSize: 30,
            position: 'relative',
            paddingLeft: 3,
            justifyContent: 'flex-start',
            fontFamily: 'fontSpring',
            borderBottomWidth: 0,
          },
        })}
      />
      <DetailsStack.Screen
        name="Details"
        component={Details}
        options={({ route, navigation: { goBack } }) => ({
          headerShown: true,
          title: route.params.name,
          headerStyle: {
            backgroundColor: '#fff',
            height: 45,
            borderBottomWidth: 0.1,
          },
          headerTitleStyle: {
            fontWeight: '500',
            fontSize: 20,
            position: 'relative',
            color: 'black',
            justifyContent: 'center',
            alignSelf: 'center',
            right: 20,
          },

          headerLeft: (props) => (
            <TouchableOpacity
              onPress={() => {
                goBack()
              }}
            >
              <Ionicons name="ios-chevron-back" size={35} {...props} />
            </TouchableOpacity>
          ),
        })}
      />
    </UserStack.Navigator>
  )
}
const styles = StyleSheet.create({
  rightIconHeader: {
    position: 'relative',
    right: 20,
  },
})
