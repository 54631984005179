import React, { Component, useState, useEffect } from 'react'
import { StyleSheet, FlatList, ActivityIndicator } from 'react-native'
import { View } from '../components/Themed'
import Item from '../components/Item'
import Search from '../components/Search'
import api from '../src/plugins/api'

export default function SearchScreen({ navigation }: any) {
  const [selectedCategoryId] = useState(-1)
  const [pageSize] = useState(20)
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [isListEnd, setIsListEnd] = useState(false)
  let [items, setItems]: any = useState([])
  let [scrollRef, setScrollRef]: any = useState()

  const getData = () => {
    if (!loading && !isListEnd) {
      setLoading(true)
      setCurrentPage(currentPage + 1)
      api()
        .get(`products${prepareQuery('')}`)
        .then((response: any) => {
          console.log(response.data.data)
          if (response.data.data.length > 0) {
            let responseItems: any = response.data.data
            let newArry = [...items, ...responseItems]
            setItems(newArry)
            setLoading(false)
          } else {
            setIsListEnd(true)
            setLoading(false)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }
  const searchInput = (text: string) => {
    setCurrentPage(1)
    api()
      .get(`products${prepareQuery(text)}`)
      .then((response: any) => {
        items = response.data.data
        setItems(items)
        let animatedScroll: any = scrollRef.scrollToOffset({
          offset: 0,
          animated: true,
        })
        setScrollRef(animatedScroll)
      })
  }

  useEffect(() => {
    getData()
  }, [])

  const prepareQuery = (text: string) => {
    const valueToSearch: any = text.toLowerCase()
    return `?qs=${valueToSearch}&page-size=${pageSize}&page=${currentPage}&group_id=${selectedCategoryId}`
  }
  const renderFooter = () => {
    return (
      <View style={styles.footer}>
        {loading ? (
          <ActivityIndicator color="black" style={{ margin: 15 }} />
        ) : null}
      </View>
    )
  }
  const Product = ({ item, index, separator }: any) => {
    return (
      <View>
        <Item itemData={item} key={index} navigation={navigation} />
      </View>
    )
  }
  return (
    <View style={{ flex: 1, overflow: 'hidden' }}>
      <Search onSearchChange={searchInput} />
      <View style={{ flex: 1, overflow: 'hidden' }}>
        <FlatList
          ref={(list: any) => setScrollRef(list)}
          columnWrapperStyle={{
            alignItems: 'center',
            alignSelf: 'center',
          }}
          numColumns={2}
          scrollEnabled={true}
          renderItem={Product}
          data={items}
          initialNumToRender={10}
          keyExtractor={(item, index) => index.toString()}
          ListFooterComponent={renderFooter}
          onEndReached={getData}
          onEndReachedThreshold={0.5}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  itemsContainer: {
    justifyContent: 'space-evenly',
    // flex: 1,
    marginTop: 10,
  },
  footer: {
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
})
