import React, { useState } from 'react'
import { Text, View, StyleSheet, Image } from 'react-native'
// @ts-ignore
import Modal from 'modal-enhanced-react-native-web'

let onPress = () => {}

const renderModalContent = (image: any) => (
  <View style={styles.wrapperContent}>
    <View style={styles.content}>
      <Image
        style={styles.image}
        source={{ uri: `https://ecommerce.inovacion.al/${image}` }}
        accessible
      />
      <Text style={styles.cartText}>Added to Cart</Text>

      <Text onPress={onPress} style={styles.viewCart}>
        View Cart
      </Text>
    </View>
  </View>
)

const CartPopUp = ({ image, isModalVisible, navigation, onClose }: any) => {
  if (isModalVisible) {
    setTimeout(() => {
      onClose(false)
    }, 3000)
  }

  onPress = () => navigation.navigate('Cart')

  return (
    <Modal isVisible={isModalVisible} backdropOpacity={0}>
      {renderModalContent(image)}
    </Modal>
  )
}

export default CartPopUp
const styles = StyleSheet.create({
  wrapperContent: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    padding: 12,
    width: '100%',
    flexDirection: 'column',

    borderRadius: 10,
    borderColor: 'rgba(0, 0, 0, 0.1)',
    position: 'absolute',
    bottom: 0,
  },
  content: {
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  image: {
    width: 40,
    height: 40,
    borderRadius: 5,
    alignContent: 'center',
  },
  cartText: {
    fontSize: 15,
    justifyContent: 'center',
    alignSelf: 'center',
    textAlignVertical: 'center',
    paddingLeft: 15,
    color: 'white',
    fontWeight: '400',
  },
  viewCart: {
    color: 'white',
    fontWeight: '800',
    textAlignVertical: 'center',
    alignSelf: 'center',
    fontSize: 18,
    position: 'absolute',
    right: 10,
  },
})
