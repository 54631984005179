import React, { useState, useEffect } from 'react'
import { StyleSheet, FlatList, TouchableOpacity } from 'react-native'
import CartItem from '../components/CartItem'
import { Text, View } from '../components/Themed'

const CartScreen = ({ navigation, route }: any) => {
  const [localStorageItem, setLocalStorageItem]: any = useState({})
  useEffect(
    () =>
      navigation.addListener('focus', () => {
        const localStorageCart: any = JSON.parse(localStorage.getItem('cart'))
        setLocalStorageItem(localStorageCart)
      }),
    []
  )
  const cartItems = ({ item, index, separator }: any) => {
    console.log(item, 'this is cart item !')
    return (
      <CartItem
        item={localStorageItem[index]}
        navigation={navigation}
        key={index}
      />
    )
  }

  const noItemsToShow = () => {
    if (localStorageItem !== null) {
      return (
        <FlatList
          renderItem={cartItems}
          data={localStorageItem}
          keyExtractor={(item, index) => index.toString()}
        />
      )
    } else return <Text>There No Item Added to Cart</Text>
  }
  const proceedToCheckout = () => {
    const token: string = localStorage.getItem('@token')
    if (token) {
      alert('Procced')
      return
    }
    navigation.navigate('Login')
  }

  return (
    <View style={styles.cartWrapper}>
      <View style={styles.itemContainer}>{noItemsToShow()}</View>
      <View style={styles.checkoutContainer}>
        <View style={styles.checkoutDetails}>
          <View style={styles.shippingDetails}>
            <Text style={styles.subtotal}>
              Subtotal ({localStorageItem ? localStorageItem.length : 0} items)
            </Text>
            <Text style={styles.shipping}>Free Shipping</Text>
          </View>

          <Text style={styles.totalAmount}>$ 120</Text>
        </View>
        <TouchableOpacity onPress={() => proceedToCheckout()}>
          <View style={styles.checkoutBtn}>
            <Text style={{ color: 'white', fontWeight: '600', fontSize: 15 }}>
              Proceed to Checkout
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  cartWrapper: {
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    paddingHorizontal: 15,
  },
  itemContainer: {
    marginTop: 10,
    flex: 3,
    paddingBottom: 10,
  },
  checkoutContainer: {
    flex: 1,
    borderTopColor: '#e5e5e5',
    borderTopWidth: 0.6,
  },
  checkoutBtn: {
    backgroundColor: '#4092E2',
    color: '#fff',
    fontFamily: 'sans-serif',
    borderRadius: 8,
    height: 40,
    width: '100%',
    justifyContent: 'center',
    fontSize: 15,
    textAlign: 'center',
    fontWeight: '400',
    marginTop: 30,
  },
  checkoutDetails: {
    flexDirection: 'row',
    paddingTop: 10,
  },
  shippingDetails: {
    flexDirection: 'column',
    flex: 1,
  },
  subtotal: {
    fontWeight: '600',
    fontSize: 14,
    fontFamily: 'sans-serif',
  },
  shipping: {
    color: '#B7B7B7',
    fontSize: 12,
    fontWeight: '500',
  },
  totalAmount: {
    color: '#4092E2',
    fontSize: 14,
    fontWeight: '700',
  },
})
export default CartScreen
