import React, { useState, useRef } from 'react'
import {
  View,
  Text,
  TouchableWithoutFeedback,
  StyleSheet,
  Animated,
  Easing,
  ScrollView,
} from 'react-native'
import { MaterialIcons } from '@expo/vector-icons'

const Accordion = ({ title, children }: any) => {
  const [open, setOpen] = useState(false)
  const animatedController = useRef(new Animated.Value(0)).current
  const [bodySectionHeight, setBodySectionHeight]: Array<any> = useState()
  const bodyHeight: any = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, bodySectionHeight],
  })
  const arrowAngle = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ['0rad', `${Math.PI}rad`],
  })
  const toggleListItem = () => {
    Animated.timing(animatedController, {
      useNativeDriver: true,
      duration: open ? 500 : 300,
      toValue: open ? 0 : 1,
      easing: Easing.bezier(0.4, 0.0, 0.2, 1),
    }).start()
    setOpen(!open)
  }

  return (
    <ScrollView
      ref={(scroller) => {
        scroller = scroller
      }}
    >
      <TouchableWithoutFeedback
        onPress={() => {
          toggleListItem()
        }}
      >
        <View style={styles.titleContainer}>
          <Text style={{ color: '#B7B7B7' }}>{title}</Text>
          <Animated.View style={{ transform: [{ rotateZ: arrowAngle }] }}>
            <MaterialIcons
              name="keyboard-arrow-down"
              size={24}
              color="#B7B7B7"
            />
          </Animated.View>
        </View>
      </TouchableWithoutFeedback>
      <Animated.View style={[styles.bodyBackground, { height: bodyHeight }]}>
        <View
          style={styles.bodyContainer}
          onLayout={(event) =>
            setBodySectionHeight(event.nativeEvent.layout.height)
          }
        >
          {children}
        </View>
      </Animated.View>
    </ScrollView>
  )
}
export default Accordion
const styles = StyleSheet.create({
  bodyBackground: {
    backgroundColor: '#EFEFEF',
    overflow: 'hidden',
    borderRadius: 10,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: '1rem',
  },
  bodyContainer: {
    padding: '1rem',
    paddingLeft: '1.5rem',
    position: 'absolute',
    bottom: 0,
  },
})
