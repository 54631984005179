import AsyncStorage from '@react-native-async-storage/async-storage'
import axios from 'axios'
import { BASE_URL, SECTOR_ID } from '@env'
export default (axiosOptions: any = {}, withoutAuthorization: any = false) => {
  const defaultOptions: any = {
    baseURL: BASE_URL + `/api`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: '',
      'Sector-id': '',
    },
  }
  let accessToken = localStorage.getItem('@token')

  console.log(accessToken, 'tokeennnn')
  if (SECTOR_ID !== '') {
    defaultOptions.headers['Sector-id'] = SECTOR_ID
  }

  let options = { ...defaultOptions, ...axiosOptions }
  if (!withoutAuthorization) {
    options.headers.Authorization = 'Bearer ' + accessToken
  }
  return axios.create(options)
}
