import { StackScreenProps } from '@react-navigation/stack'
import React, { useEffect, useState } from 'react'
import api from '../src/plugins/api'
import { Feather } from '@expo/vector-icons'
import { CLIENT_SECRET, CLIENT_ID } from '@env'
import AsyncStorage from '@react-native-async-storage/async-storage'
import {
  StyleSheet,
  TextInput,
  View,
  Button,
  TouchableOpacity,
  Text,
} from 'react-native'
// import ClientModels from '../store/models/UserModels'
import { RootStackParamList } from '../types'

export default function Login({
  navigation,
}: StackScreenProps<RootStackParamList, 'Login'>) {
  const [username, onChangeUsername] = useState('')
  const [password, onChangePassword] = useState('')
  const [data, setData] = useState([])

  const Login = (username: string, password: string) => {
    let payload = {
      provider: 'local',
      email: username,
      password: password,
      grant_type: 'password',
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      username: username,
      scope: '*',
    }
    api()
      .post('login', payload)
      .then((response) => {
        if (!(response.data.status === 0)) {
          alert('error')
          return
        }
        api()
          .post('oauth/token', payload)
          .then((resultsToken) => {
            if (resultsToken) {
              localStorage.setItem('@token', resultsToken.data.access_token)
              navigation.goBack()
            } else alert('Username or Password Wrong !')
          })
      })
      .catch((e) => {
        if (e.response.data.status) {
          alert('Password ose Email Gabim !')
        }
      })
  }

  return (
    <View>
      <Text style={styles.closeModal} onPress={() => navigation.goBack()}>
        <Feather name="x" size={30} color="#B7B7B7" />
      </Text>

      <View
        style={{
          flexDirection: 'column',
          justifyContent: 'center',
          marginTop: 200,
          width: '100%',
          alignItems: 'center',
          alignContent: 'center',
          alignSelf: 'center',
        }}
      >
        <View
          style={{
            alignSelf: 'center',
            width: '90%',
            justifyContent: 'center',
            top: '50%',
            alignContent: 'center',
          }}
        >
          <TextInput
            style={styles.input}
            value={username}
            placeholder="Username"
            onChangeText={onChangeUsername}
          />
          <TextInput
            style={styles.input}
            value={password}
            placeholder="Password"
            onChangeText={onChangePassword}
            secureTextEntry={true}
          />
          <TouchableOpacity onPress={() => Login(username, password)}>
            <View style={styles.checkoutBtn}>
              <Text
                style={{
                  color: 'white',
                  fontWeight: '600',
                  fontSize: 15,
                  top: 8,
                  position: 'relative',
                }}
              >
                Login
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    borderColor: '#4092E2',
    borderRadius: 10,
    textAlign: 'center',
  },
  checkoutBtn: {
    backgroundColor: '#4092E2',
    fontFamily: 'sans-serif',
    borderRadius: 8,
    height: 40,
    width: '80%',
    fontSize: 15,
    textAlign: 'center',
    fontWeight: '400',
    marginVertical: 25,
    alignSelf: 'center',
    alignItems: 'center',
  },
  closeModal: {
    fontWeight: '700',
    fontSize: 28,
    color: 'black',
    alignSelf: 'flex-end',
    position: 'relative',
    right: 25,
    top: 10,
  },
})
