import React, { useState, useEffect } from 'react'
import { StyleSheet } from 'react-native'
import { Text, View } from '../components/Themed'

export default function FavoriteScreen({ navigation, route }: any) {
  alert(1)
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Favorites Screen</Text>
      <View
        style={styles.separator}
        lightColor="#eee"
        darkColor="rgba(255,255,255,0.1)"
      />
      {/* <EditScreenInfo path="/screens/TabOneScreen.tsx" /> */}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'purple',
  },
  title: {
    fontSize: 20,
    color: 'white',
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
})
