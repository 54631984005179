import React, { useState } from 'react'
import { StyleSheet } from 'react-native'
import { Text, View } from '../components/Themed'
export default function DetailsScreen({ route }) {
  return (
    <View style={styles.container}>
      <Text style={styles.dumbText}>{route.params.name}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    width: '100%',
  },
  title: {
    fontSize: 20,
    color: 'white',
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  listStyle: {
    fontSize: 20,
    fontWeight: '600',
    height: 50,
    marginLeft: 20,
  },
  iconStyle: {
    position: 'relative',
    top: 3,
    left: 20,
    fontSize: 23,
  },
  dumbText: {
    fontSize: 30,
  },
})
