const { width } = Dimensions.get('window')
import React, { useState } from 'react'
import {
  Dimensions,
  Text,
  Image,
  View,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Picker,
} from 'react-native'
import { AntDesign } from '@expo/vector-icons'
import { Feather } from '@expo/vector-icons'

const goTo = (navigation: any, item: Object) => {
  navigation.navigate('ItemDetails', {
    id: item.id,
  })
}
const longText = (text: string) => {
  if (text && text.length > 18) {
    return text.substring(0, 25) + '...'
  }
  if (text) {
    return text
  }
  return ''
}
const favoriteIcon = (isFav: boolean) => {
  return (
    <AntDesign
      style={styles.itemFavorite}
      name={isFav ? 'heart' : 'hearto'}
      color={isFav ? '#dc3545' : 'white'}
      size={22}
    />
  )
}

const CartItem = ({ item, navigation }: any) => {
  const [quantity, setQuantity]: any = useState(1)
  const [isFavorite, setToFavorite] = useState(item.isFavorite)
  const [selectedValue, setSelectedValue] = useState('M')
  const removeFromCart = (itemz) => {
    alert('1')
  }
  return (
    <View style={styles.itemContainer}>
      <Image
        source={{
          uri: `https://ecommerce.inovacion.al/` + item.full_image_default,
        }}
        accessible
        style={styles.itemImage}
      />
      <TouchableOpacity onPress={() => setToFavorite(true)}>
        {favoriteIcon(isFavorite)}
      </TouchableOpacity>
      <View style={styles.itemDetails}>
        <Text onPress={() => goTo(navigation, item)} style={styles.itemName}>
          {longText(item.name)}
        </Text>
        <Text style={styles.itemPrice}>$ {item.sale_price}</Text>

        <Text
          style={{ flex: 1, position: 'absolute', right: 0, fontWeight: '700' }}
          onPress={() => removeFromCart(item)}
        >
          <Feather name="x" size={20} color="#B7B7B7" />
        </Text>
        <View style={styles.itemAction}>
          <View style={styles.cartQuantity}>
            <TouchableOpacity onPress={() => setQuantity(quantity - 1)}>
              <View style={styles.quantity}>
                <Text
                  style={{ color: 'black', fontWeight: '700', fontSize: 24 }}
                >
                  -
                </Text>
              </View>
            </TouchableOpacity>
            <TextInput
              style={styles.quantityInput}
              keyboardType="number-pad"
              value={quantity}
            />
            <TouchableOpacity onPress={() => setQuantity(quantity + 1)}>
              <View style={styles.quantity}>
                <Text
                  style={{ color: 'black', fontWeight: '700', fontSize: 24 }}
                >
                  +
                </Text>
              </View>
            </TouchableOpacity>
          </View>
          <View
            style={{
              borderRadius: 5,
              borderWidth: 1,
              borderColor: '#efefef',
              height: 27,
              width: 80,
              marginRight: 10,
              flex: 1,
            }}
          >
            <Picker
              selectedValue={selectedValue}
              style={{
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                width: 90,
                zIndex: 999,
              }}
              onValueChange={(itemValue, itemIndex) =>
                setSelectedValue(itemValue)
              }
            >
              <Picker.Item label="XS" value="xSmall" />
              <Picker.Item label="S" value="Small" />
              <Picker.Item label="M" value="Medium" />
              <Picker.Item label="XL" value="Large" />
              <Picker.Item label="XXL" value="xLarge" />
            </Picker>
            {/* <Entypo
              name="chevron-down"
              size={24}
              color="black"
              style={{
                textAlignVertical: 'center',
                position: 'relative',
                right: 15,
              }}
            /> */}
          </View>
        </View>
      </View>
    </View>
  )
}

export default CartItem

const styles = StyleSheet.create({
  itemContainer: {
    width: '100%',
    height: 120,
    padding: 10,
    borderWidth: 0.8,
    borderColor: '#efefef',
    shadowColor: '#efefef',
    textShadowOffset: '#4092E2',
    elevation: 1,
    shadowRadius: 10,
    flexDirection: 'row',
    borderRadius: 10,
    marginBottom: 15,
  },
  itemImage: {
    justifyContent: 'flex-start',
    width: '40%',
    height: '100%',
    resizeMode: 'contain',
  },
  itemDetails: {
    paddingHorizontal: 10,
    width: '60%',
  },
  itemName: {
    fontSize: 14,
    fontWeight: '600',
    width: '80%',
  },
  itemPrice: {
    color: '#4092E2',
    fontWeight: '700',
    paddingTop: 5,
  },
  itemAction: {
    position: 'absolute',
    bottom: 0,
    flexDirection: 'row',
    width: '100%',
  },
  cartQuantity: {
    flex: 5,
    flexDirection: 'row',
  },
  quantityInput: {
    width: 35,
    borderColor: '#efefef',
    borderWidth: 0.2,
    borderRadius: 100,
    color: 'black',
    position: 'relative',
    textAligsn: 'center',
    textAlignVertical: 'bottom',
    textAlign: 'center',
  },
  quantity: {
    width: 25,
    height: 25,
    backgroundColor: '#e4e4e4',
    justifyContent: 'center',
    alignContent: 'center',
    borderRadius: 5,
    alignItems: 'center',
    position: 'relative',
    paddingBottom: 5,
  },
  itemFavorite: {
    fontWeight: '700',
    marginEnd: width >= 640 ? 10 : 0,
    position: 'absolute',
    right: 5,
    top: 5,
  },
})
