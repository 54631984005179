import React from 'react'
import { StyleSheet, View, Text, Image, Dimensions } from 'react-native'
import Carousel, { PaginationLight } from 'react-native-x-carousel'

const { width } = Dimensions.get('window')

const DATA = [
  {
    coverImageUri:
      'https://images.unsplash.com/photo-1571799508219-988c35b4d06a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80',
    cornerLabelColor: '#FFD300',
    cornerLabelText: '-50%',
  },
  {
    coverImageUri:
      'https://images.unsplash.com/photo-1526816619231-e426e697193e?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
    cornerLabelColor: '#0080ff',
    cornerLabelText: '-65%',
  },
  {
    coverImageUri:
      'https://images.unsplash.com/photo-1511892549826-a48122d9b258?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80',
    cornerLabelColor: '#2ECC40',
    cornerLabelText: '-75%',
  },
  {
    coverImageUri:
      'https://images.unsplash.com/photo-1534551039924-409372dd29c3?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1053&q=80',
    cornerLabelColor: '#2ECC40',
    cornerLabelText: '-20%',
  },
]

const App = () => {
  return (
    <View style={styles.container}>
      <Carousel
        pagination={PaginationLight}
        renderItem={carouselItem}
        data={DATA}
        loop
        autoplay
        autoplayInterval={4000}
      />
    </View>
  )
}
const carouselItem = (data) => (
  <View key={data.coverImageUri} style={styles.cardContainer}>
    <View style={styles.cardWrapper}>
      <Image style={styles.card} source={{ uri: data.coverImageUri }} />
      <View
        style={[styles.cornerLabel, { backgroundColor: data.cornerLabelColor }]}
      >
        <Text style={styles.cornerLabelText}>{data.cornerLabelText}</Text>
      </View>
    </View>
  </View>
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardWrapper: {
    borderRadius: 5,
    overflow: 'hidden',
  },
  card: {
    resizeMode: 'cover',
    width: width * 1,
    height: width * 0.8,
  },
  cornerLabel: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    borderTopLeftRadius: 8,
  },
  cornerLabelText: {
    fontSize: 12,
    color: '#fff',
    fontWeight: '600',
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 2,
    paddingBottom: 2,
  },
})

export default App
