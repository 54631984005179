import React, { useState, useEffect } from 'react'
import {
  StyleSheet,
  ScrollView,
  FlatList,
  Dimensions,
  Animated,
} from 'react-native'
import Carousel from '../components/HomeCarousel'
import { Text, View } from '../components/Themed'
import jsonCategories from '../categories.json'
import jsonItems from '../items.json'
import HomeCategories from '../components/HomeCategories'
import { CategoriesTypes } from '../types'
import Item from '../components/Item'
const { width } = Dimensions.get('window')
import api from '../src/plugins/api'

const categoryItem = (categoriesData: CategoriesTypes, index: number) => {
  return <HomeCategories key={index} categories={categoriesData.item} />
}

const HomeScreen = ({ navigation }: any) => {
  const [selectedCategoryId] = useState(-1)
  const [searchValue] = useState('')
  const [pageSize] = useState(60)
  const [currentPage] = useState(1)
  let [items, setItems] = useState([])

  const prepareQuery = () => {
    let sector = ''
    // if (this.sectorId) {
    //   sector = `&sector_id=${this.sectorId}`
    // }
    const valueToSearch: string = searchValue.toLowerCase()
    return `?qs=${valueToSearch}${sector}&page-size=${pageSize}&page=${currentPage}&group_id=${selectedCategoryId}`
  }
  const Product = ({ item, index, separator }: any) => {
    return <Item itemData={item} key={index} navigation={navigation} />
  }

  useEffect(() => {
    api()
      .get(`products${prepareQuery()}`)
      .then((response: any) => {
        items = response.data.data
        setItems(items)
      })
  }, [])

  const [json] = useState(jsonCategories)
  const [itemsjson] = useState(jsonItems)
  return (
    <ScrollView style={{ backgroundColor: 'white' }} stickyHeaderIndices={[1]}>
      <View style={styles.carouselContainer}>
        <Carousel />
      </View>
      <View style={styles.categoriesContainer}>
        <FlatList
          horizontal
          renderItem={categoryItem}
          data={json}
          keyExtractor={(item, index) => index.toString()}
        />
      </View>
      <View style={styles.itemsContainer}>
        <FlatList
          columnWrapperStyle={{
            alignItems: 'center',
            alignSelf: 'center',
          }}
          numColumns={2}
          renderItem={Product}
          data={items}
          keyExtractor={(items, index) => index.toString()}
        />
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  carouselContainer: {
    backgroundColor: 'white',
    width: '100%',
    height: 'auto',
    marginBottom: 20,
  },
  text: {
    position: 'absolute',
    color: 'black',
  },
  categoriesContainer: {
    width: '100%',
    height: 'auto',
    paddingTop: 10,
    paddingBottom: 10,
  },
  itemsContainer: {
    width: 'auto',
    flexDirection: 'row',
    minWidth: 320,
    justifyContent: width <= 370 ? 'flex-start' : 'space-evenly',
    height: 'auto',
    flexWrap: 'wrap',
    paddingLeft: 5,
    paddingRight: 5,
    marginTop: 20,
  },
})

export default HomeScreen
