import React, { useState } from 'react'
import { StyleSheet, TextInput, View } from 'react-native'
import { AntDesign } from '@expo/vector-icons'

const Search = ({ onSearchChange }: any) => {
  const [searchValue, setSearchValue] = useState('')

  const clearSearch = () => {
    setSearchValue('')
    onSearchChange('')
  }

  const onKeyUp = (text: string) => {
    setSearchValue(text)
    onSearchChange(text)
  }

  const showClearIcon = () => {
    if (!!searchValue) {
      return (
        <View>
          <AntDesign
            style={styles.closeIcon}
            name="closecircleo"
            size={18}
            color="#dcdcdc"
            onPress={() => {
              clearSearch()
            }}
          />
        </View>
      )
    } else return <View></View>
  }

  return (
    <View style={{ height: 55, backgroundColor: 'white' }}>
      <View style={styles.searchContainer}>
        <AntDesign
          name="search1"
          style={styles.icon}
          size={24}
          color="#dcdcdc"
        />
        <TextInput
          placeholder="Kërko"
          style={styles.input}
          onChangeText={(text) => onKeyUp(text)}
          value={searchValue}
        />
        {showClearIcon()}
      </View>
    </View>
  )
}

export default Search
const styles = StyleSheet.create({
  searchContainer: {
    height: 35,
    backgroundColor: '#fff',
    borderWidth: 1,
    borderColor: '#dcdcdc',
    flexDirection: 'row',
    borderRadius: 5,
    width: '90%',
    zIndex: 10,
    marginVertical: 20,
    alignSelf: 'center',
    position: 'relative',
    top: -15,
  },
  input: {
    fontSize: 16,
    paddingLeft: 10,
    paddingRight: 10,
    width: '100%',
  },
  icon: {
    paddingLeft: 10,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  closeIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginTop: 6,
    paddingHorizontal: 10,
  },
})
