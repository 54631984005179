import React, { useState } from 'react'
import { TouchableOpacity, StyleSheet, Dimensions } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import { Text, View } from '../components/Themed'
import { TouchableHighlight } from 'react-native-gesture-handler'
const { width } = Dimensions.get('window')
const longText = (text: string) => {
  if (text && text.length > 10) {
    return text.substring(0, 8) + '...'
  } else if (text) {
    return text
  } else {
    return ''
  }
}
const Categories = ({ categories }: any, props: Props) => {
  const catAction = (id: number): void => {
    alert('This is ' + id)
  }
  return (
    <TouchableOpacity
      onPress={() => {
        catAction(categories.id)
      }}
    >
      <View style={styles.categoryContainer}>
        <Ionicons name={categories.icon} size={width >= 640 ? 44 : 28} />
      </View>
      <Text style={styles.categoryName}>{longText(categories.name)}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  categoryContainer: {
    backgroundColor: '#f1f1f1',
    width: width >= 640 ? 100 : 70,
    height: width >= 640 ? 100 : 70,
    borderRadius: 100,
    marginLeft: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  categoryName: {
    position: 'relative',
    textAlign: 'center',
    fontWeight: '500',
  },
})

export default Categories
