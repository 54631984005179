import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
} from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import * as React from 'react'
import { Ionicons } from '@expo/vector-icons'
import { SimpleLineIcons } from '@expo/vector-icons'
import {
  ColorSchemeName,
  TouchableOpacity,
  StyleSheet,
  View,
  Text,
} from 'react-native'
import NotFoundScreen from '../screens/NotFoundScreen'
import LoginModal from '../screens/Login'

import { RootStackParamList, HomeParamList } from '../types'
import BottomTabNavigator from './BottomTabNavigator'
import LinkingConfiguration from './LinkingConfiguration'
import ItemDetails from '../screens/ItemDetails'

// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started
export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName
}) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
    >
      <RootNavigator />
    </NavigationContainer>
  )
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>()
const localStorageCart = JSON.parse(localStorage.getItem('cart'))
const RootStack = createStackNavigator<RootStackParamList>()
function RootNavigator() {
  return (
    <Stack.Navigator
      initialRouteName="Root"
      screenOptions={{ headerShown: false }}
      mode="modal"
    >
      <Stack.Screen name="Login" component={RootStackScreen} />
      <Stack.Screen name="Root" component={BottomTabNavigator} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} />
      <Stack.Screen
        name="ItemDetails"
        component={ItemDetails}
        options={({ navigation, route, goBack }: any) => ({
          headerShown: true,
          headerStyle: {
            backgroundColor: '#fff',
            height: 45,
            borderBottomWidth: 0.2,
          },
          headerTitle: 'Delirium',
          headerTintColor: '#000',
          headerLeft: (props) => (
            <TouchableOpacity
              onPress={() => {
                goBack()
              }}
            >
              <Ionicons name="ios-chevron-back" size={35} {...props} />
            </TouchableOpacity>
          ),
          headerRight: (props) => (
            <SimpleLineIcons
              {...props}
              name="handbag"
              style={styles.rightIconHeader}
              size={25}
              onPress={() => {
                navigation.navigate('Cart')
              }}
            >
              <View style={styles.badgeWrapper}>
                <Text style={styles.cartBadge}>{0}</Text>
              </View>
            </SimpleLineIcons>
          ),
          headerTitleStyle: {
            fontWeight: '500',
            display: 'flex',
            fontSize: 30,
            position: 'relative',
            marginLeft: -10,
            justifyContent: 'center',
            fontFamily: 'fontSpring',
            borderBottomWidth: 0,
          },
        })}
      />
    </Stack.Navigator>
  )
}

function RootStackScreen() {
  return (
    <RootStack.Navigator mode="modal" headerMode="none">
      <Stack.Screen name="Login" component={LoginModal} />
    </RootStack.Navigator>
  )
}
const styles = StyleSheet.create({
  rightIconHeader: {
    position: 'relative',
    right: 20,
  },
  badgeWrapper: {
    width: 15,
    height: 15,
    borderRadius: 10,
    justifyContent: 'center',
    backgroundColor: 'black',
    flexDirection: 'row',
    bottom: 12,
  },
  cartBadge: {
    top: 1,
    fontSize: 10,
    position: 'relative',
    fontWeight: '600',
    color: 'white',
  },
})
