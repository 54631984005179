import React, { useState, useEffect } from 'react'
import {
  StyleSheet,
  FlatList,
  Image,
  Dimensions,
  TouchableOpacity,
} from 'react-native'
import Accordion from '../components/Accordion'
import { Text, View } from '../components/Themed'
import { AntDesign } from '@expo/vector-icons'
import { Entypo } from '@expo/vector-icons'
import { Feather } from '@expo/vector-icons'
const { width } = Dimensions.get('window')
import { ScrollView } from 'react-native-gesture-handler'
import Item from '../components/Item'
import CartPopUp from '../components/Modals/CartPopUp'
import api from '../src/plugins/api'

const renderImages = ({ item, index }: any) => {
  return (
    <View style={styles.imageCover}>
      <Image
        source={{ uri: `https://ecommerce.inovacion.al/` + item }}
        key={index}
        accessible
        style={styles.itemImage}
      />
    </View>
  )
}
const favoriteIcon = (isFav: boolean) => {
  console.log(isFav)
  return (
    <AntDesign
      style={styles.itemFavorite}
      name={isFav ? 'heart' : 'hearto'}
      size={20}
    />
  )
}

const ItemDetailsScreen = ({ route, navigation }: any) => {
  // let index = jsonItems.findIndex((item) => route.params.id === item.id)
  // const [isFavorite, setToFavorite] = useState(jsonItems[index].isFavorite)
  const [isModalVisible, setModalVisible] = useState(false)
  const [cartData, setCartData]: any = useState([])
  let [itemPhotos, setItemPhotos] = useState([])
  let [itemDetails, setItemDetails] = useState([])
  let [sameProducts, setSameProducts] = useState([])

  useEffect(() => {
    console.log(itemDetails)
    api()
      .get(`products/${route.params.id}?resource=ProductDetailedResource`)
      .then((response: any) => {
        itemPhotos = response.data.data[0].product.full_photos
        itemDetails = response.data.data[0].product
        sameProducts = response.data.data[0].similar_products

        // response.data.data.forEach((data: any) => {
        //    itemPhotos = data.product.photos.map(
        //     (item: object, index: number) => ({
        //       id: index + 1,
        //       url: `https://ecommerce.inovacion.al/${item}`,
        //     })
        //   )
        //   console.log(itemPhotos, 'this is item pgo')

        // })
        localStorage.getItem('cart')
        setItemPhotos(itemPhotos)
        setItemDetails(itemDetails)
        setSameProducts(sameProducts)
      })
  }, [route.params.id])

  const Product = ({ item, index, separator }: any) => {
    return <Item itemData={item} key={index} navigation={navigation} />
  }
  const addToCart = (item: any) => {
    // setCartData((prevArray: any) => [...prevArray, item])
    // console.log(cartData, 'this is cart data ')
    cartData.push(item)
    console.log(cartData, 'this is cartData')
    localStorage.setItem('cart', JSON.stringify(cartData))

    localStorage.setItem('cart', JSON.stringify(cartData))
    setModalVisible(!isModalVisible)
  }
  const closeModal = (isopen: boolean) => {
    setModalVisible(isopen)
  }

  return (
    <ScrollView style={{ backgroundColor: 'white' }}>
      <CartPopUp
        image={itemDetails.full_image_default}
        isModalVisible={isModalVisible}
        navigation={navigation}
        onClose={closeModal}
      />
      <View style={styles.container}>
        <View>
          <FlatList
            horizontal
            renderItem={renderImages}
            data={itemPhotos}
            keyExtractor={(item, index) => index.toString()}
          />
        </View>
        <View style={styles.details}>
          <View style={styles.informationWrapper}>
            <View style={styles.leftSection}>
              <Text style={styles.title}>{itemDetails.name}</Text>
              <Text style={styles.price}>$ {itemDetails.sale_price}</Text>
            </View>
            <View style={styles.rightSection}>
              <Feather
                name="share"
                style={styles.rightIconHeader}
                size={20}
                onPress={() => {
                  alert('Share It !')
                }}
              />

              {/* <TouchableOpacity onPress={() => setToFavorite(true)}>
                {favoriteIcon(isFavorite)}
              </TouchableOpacity> */}
              <Text style={styles.sizeDropdown}>
                Size
                <Entypo
                  style={styles.icon}
                  name="chevron-thin-down"
                  size={18}
                  color="#b7b7b7"
                />
              </Text>
            </View>
          </View>
          <TouchableOpacity onPress={() => addToCart(itemDetails)}>
            <View style={styles.checkoutBtn}>
              <Text style={{ color: 'white', fontWeight: '600', fontSize: 15 }}>
                Add to Cart
              </Text>
            </View>
          </TouchableOpacity>

          <View style={styles.border} />
          <Accordion title={'Description'}>
            <Text>{itemDetails.description}</Text>
          </Accordion>
          <Accordion title={'Shipping & Special Requirments'}>
            <Text>{itemDetails.description}</Text>
          </Accordion>
        </View>

        <View style={styles.itemsContainer}>
          <Text style={styles.suggestionTitle}>
            {sameProducts.length === 0 ? '' : 'You might also like'}
          </Text>
          <FlatList
            columnWrapperStyle={{
              alignItems: 'center',
              alignSelf: 'center',
            }}
            numColumns={width > 600 ? 3 : 2}
            renderItem={Product}
            data={sameProducts}
            keyExtractor={(item, index) => index.toString()}
          />
        </View>
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },

  details: {
    marginHorizontal: 30,
    marginBottom: 30,
  },
  informationWrapper: {
    flexDirection: 'row',
    paddingTop: 20,
    borderBottomColor: '#e5e5e5',
    borderBottomWidth: 0.8,
    paddingBottom: 25,
  },
  rightSection: {
    alignItems: 'flex-end',
    flex: 1,
  },
  title: {
    fontWeight: '500',
    fontSize: 15,
    fontStyle: 'normal',
  },
  price: {
    color: '#4092E2',
    fontWeight: '700',
    fontSize: 16,
    marginTop: 10,
  },
  leftSection: {
    flex: 4,
  },
  sizeDropdown: {
    fontWeight: '500',
    fontSize: 16,
    color: '#b7b7b7',
    marginTop: 10,
    alignItems: 'flex-start',
  },
  checkoutBtn: {
    backgroundColor: '#4092E2',
    color: '#fff',
    fontFamily: 'sans-serif',
    borderRadius: 8,
    height: 40,
    width: '100%',
    justifyContent: 'center',
    fontSize: 15,
    textAlign: 'center',
    fontWeight: '400',
    marginVertical: 25,
  },
  border: {
    borderBottomWidth: 0.8,
    borderBottomColor: '#e5e5e5',
  },
  icon: {
    paddingLeft: 10,
  },

  itemImage: {
    width: width * 1,
    height: width * 0.7,
    resizeMode: 'contain',
  },
  imageCover: {
    borderRadius: 2,
    overflow: 'hidden',
  },
  suggestionTitle: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    alignContent: 'flex-start',
    textAlign: 'right',
    paddingBottom: 20,
    fontSize: 16,
    fontFamily: 'sans-serif',
    fontWeight: '700',
  },
  itemFavorite: {
    fontWeight: 'bold',
    color: '#dc3545',
  },
  itemsContainer: {
    width: 'auto',
    flexDirection: 'row',
    minWidth: 320,
    justifyContent: width <= 370 ? 'flex-start' : 'space-evenly',
    height: 'auto',
    flexWrap: 'wrap',
    paddingLeft: 5,
    paddingRight: 5,
    marginTop: 20,
  },
  rightIconHeader: {
    position: 'relative',
    top: 0,
    right: 40,
  },
  container1: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    backgroundColor: 'lightblue',
    padding: 12,
    margin: 16,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderColor: 'rgba(0, 0, 0, 0.1)',
  },
})
export default ItemDetailsScreen
